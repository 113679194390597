'use client'; // Error components must be Client Components

import { useEffect, useState } from 'react';
import style from './error.module.css';
export default function NotFound() {
    const [state, setState] = useState(<></>);

    useEffect(() => {
        async function loadErrorPage() {
            setState(
                <>
                    <iframe src={`/404`} className={style.iframe} />
                </>,
            );
        }

        loadErrorPage();
    }, []);

    return state;
}
